import React, { useState, FC, useEffect } from 'react';
import { signIn, confirmSignIn, fetchAuthSession, signOut } from 'aws-amplify/auth';
import { useFormik } from 'formik';
import AWS from 'aws-sdk';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import logo from '../assets/images/logo.svg';

const validationSchema = yup.object({
  email: yup.string().email('Invalid email format').required('Email required'),
  password: yup.string().required('Password required'),
});

const year = new Date().getFullYear();
const companyName = 'BlueLightAI';

const AuthenticationForm: FC = () => {
  const [loading, setLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [packageName, setPackageName] = useState('');
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadComplete, setDownloadComplete] = useState(false);

  const updateAWSCredentials = (credentials: {
    accessKeyId: string;
    secretAccessKey: string;
    sessionToken?: string;
    expiration?: Date;
  }) => {
    AWS.config.update({
      credentials: new AWS.Credentials({
        accessKeyId: credentials.accessKeyId,
        secretAccessKey: credentials.secretAccessKey,
        sessionToken: credentials.sessionToken,
      }),
    });
  };

  const getCurrentSession = async () => {
    try {
      const { credentials } = await fetchAuthSession({ forceRefresh: true });

      if (credentials) {
        updateAWSCredentials(credentials);
        setIsLoggedIn(true);
      }
    } catch (e: any) {
      toast.error(e.message, {
        position: 'top-right',
      });
    }
  };

  useEffect(() => {
    getCurrentSession();
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      const ssmClient = new AWS.SSM({
        region: process.env.REACT_APP_AWS_PROJECT_REGION as string,
      });

      ssmClient.getParameter(
        {
          Name: process.env.REACT_APP_AWS_SSM_PACKAGE_NAME as string,
        },
        (err, data) => {
          if (err) {
            toast.error(err.message, {
              position: 'top-right',
            });
            return;
          }

          if (data?.Parameter?.Value) {
            setPackageName(data.Parameter.Value);
          }
        }
      );
    }
  }, [isLoggedIn]);

  const login = async (email: string, password: string): Promise<void> => {
    setLoading(true);
    try {
      const { nextStep } = await signIn({
        username: email,
        password,
        options: {
          authFlowType: 'USER_PASSWORD_AUTH',
        },
      });

      if (nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
        await confirmSignIn({ challengeResponse: password });
      }

      await getCurrentSession();
    } catch (e: any) {
      toast.error(e.message, {
        position: 'top-right',
      });
    } finally {
      setLoading(false);
    }
  };

  const downloadFile = (): void => {
    setDownloadComplete(false);
    setIsDownloading(true);

    const s3 = new AWS.S3({
      params: { Bucket: process.env.REACT_APP_AWS_BUCKET_NAME as string },
    });

    const params = {
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME as string,
      Key: packageName,
    };

    s3.getObject(params, function (err, data) {
      if (err) {
        toast.error(err.message, {
          position: 'top-right',
        });
        setIsDownloading(false);
        return;
      }

      // @ts-ignore
      const blob = new Blob([data.Body], { type: 'application/zip' });
      const blobUrl = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = packageName;

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);

      window.URL.revokeObjectURL(blobUrl);

      setIsDownloading(false);
      setDownloadComplete(true);
    });
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      await login(values.email, values.password);
    },
  });

  return (
    <div className='d-flex justify-content-center align-items-center' style={{ width: '100vw' }}>
      <div className='authentication-form'>
        <div className='position-absolute top-0 start-0 p-4'>
          <img src={logo} alt='Logo' />
        </div>
        <div className='position-absolute bottom-0 start-0 p-4'>
          &copy; {year} {companyName} - All Rights Reserved.
        </div>
        {loading ? (
          <div className='d-flex justify-content-center align-items-center'>
            <div className='spinner-border text-primary' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        ) : isLoggedIn ? (
          <div className='d-flex flex-column' style={{ height: '100%' }}>
            <h4>You have successfully logged in!</h4>
            <div className='mt-4'>
              <button type='button' onClick={downloadFile} className='btn btn-primary'>
                Download Cobalt
              </button>
              <div className='mt-2'>
                {isDownloading && (
                  <div className='d-flex align-items-center justify-content-start mt-3'>
                    <div className='spinner-border text-primary' role='status'>
                      <span className='visually-hidden'>Downloading...</span>
                    </div>
                    <span className='m-2'>Downloading...</span>
                  </div>
                )}
                {downloadComplete && (
                  <div className='alert alert-success mt-3' role='alert'>
                    Download Complete!
                  </div>
                )}
              </div>
            </div>
            <button
              type='button'
              onClick={() => signOut().then(() => setIsLoggedIn(false))}
              className='btn btn-sm btn-link mt-auto p-0 text-md-start'
            >
              Sign Out
            </button>
          </div>
        ) : (
          <>
            <h2 className='mb-4 text-center'>Sign in</h2>
            <form onSubmit={formik.handleSubmit}>
              <div className='mb-3'>
                <label htmlFor='email' className='form-label'>
                  Email:
                </label>
                <input
                  type='text'
                  id='email'
                  name='email'
                  className='form-control'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className='text-danger'>{formik.errors.email}</div>
                ) : null}
              </div>

              <div className='mb-3'>
                <label htmlFor='password' className='form-label'>
                  Password:
                </label>
                <input
                  type='password'
                  id='password'
                  name='password'
                  className='form-control'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                />
                {formik.touched.password && formik.errors.password ? (
                  <div className='text-danger'>{formik.errors.password}</div>
                ) : null}
              </div>

              <button type='submit' className='btn btn-primary mt-3'>
                Sign In
              </button>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default AuthenticationForm;
