import React from 'react';
import { Toaster } from 'react-hot-toast';
import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';

import AuthenticationForm from './components/AuthenticationForm';

const App = () => (
  <div className='gradient-background'>
    <AuthenticationForm />
    <Toaster />
  </div>
);

export default App;
