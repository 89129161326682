import { Amplify } from 'aws-amplify';

export const configureAmplify = (): void => {
  Amplify.configure({
    Auth: {
      Cognito: {
        identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL as string,
        userPoolClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID as string,
        userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID as string,
      },
    },
    Storage: {
      S3: {
        bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
        region: process.env.REACT_APP_AWS_PROJECT_REGION,
      },
    },
  });
};
